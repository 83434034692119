import React from "react";
import {Create, FileField, FileInput, TextInput, ReferenceInput,SelectInput, SimpleForm, SelectArrayInput , ReferenceArrayInput } from "react-admin";
import CreateGuesser from "@api-platform/admin/lib/CreateGuesser";
import { parse } from "query-string";

const FichierCreate = (props) => {
    console.log(props)
    const { police: polices_string, objetassure: objets_assures_string, message: messages_string } = parse(props.location.search);
    const polices = polices_string ? polices_string.replace('[', '').replace(']', '').split(',') : [];
    const objetsAssures = objets_assures_string ? objets_assures_string.replace('[', '').replace(']', '').split(',') : [];
    const messages = messages_string ? messages_string.replace('[', '').replace(']', '').split(',') : [];


    return(
        <Create {...props}>
            <SimpleForm defaultValue={{police: polices, objetassure: objetsAssures, message: messages}}>
                <TextInput source="titre"/>
                <FileInput source="fichier">
                    <FileField source="src" />
                </FileInput>
                <ReferenceArrayInput  reference="police" source="police">
                    <SelectArrayInput optionText="numero"/>
                </ReferenceArrayInput >
                <ReferenceArrayInput  reference="objet_assures" source="objetassure">
                    <SelectArrayInput optionText="nom"/>
                </ReferenceArrayInput >
                <ReferenceArrayInput  reference="demande_messages" source="message">
                    <SelectArrayInput optionText="id"/>
                </ReferenceArrayInput >
            </SimpleForm>
        </Create>
    );
}

export default FichierCreate;
