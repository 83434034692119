import * as React from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from '@material-ui/core';
import {getResources, MenuItemLink, withTranslate} from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import {withRouter} from 'react-router-dom';
import {stringify} from 'query-string';


const BeBrokerMenu = ({onMenuClick, logout, translate}) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const permissions = JSON.parse(localStorage.getItem('roles'));
    return (
        <div>
            {permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') ?
                <MenuItemLink
                    to={{
                        pathname: "/taches",
                        search: stringify({
                            page: 1,
                            perPage: 10,
                            filter: JSON.stringify({current_user: true}),
                        }),
                    }}
                    primaryText="Mes tâches"
                    leftIcon={<AssignmentIndIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                /> : null }

            {resources.map(resource => {
                    if (resource.hasList) {
                        return (
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={
                                    (resource.options && resource.options.label) ||
                                    translate("resources." + resource.name + ".name", 2)
                                }
                                leftIcon={
                                    resource.icon ? <resource.icon/> : <DefaultIcon/>
                                }
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                            />
                        )
                    }
                }
            )}
            {isXSmall && logout}
        </div>
    );
};

export default withRouter(withTranslate(BeBrokerMenu));
