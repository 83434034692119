import React from "react";
import {ListGuesser} from "@api-platform/admin";
import {TextField} from "react-admin";
import {RolesField} from "./RolesField";

const ProfileList = props => {
    return (
        <ListGuesser {...props} exporter={false}>
            <TextField source="nom" label="Nom"/>
            <TextField source="prenom" label="Prénom"/>
            <TextField source="user.username" label="Nom d'utilisateur"/>
            <TextField source="user.email" label="E-mail"/>
            <RolesField source="user" field="roles"/>
        </ListGuesser>
    );
};

export default ProfileList;