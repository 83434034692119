import React from "react";
import {DateField, SelectField, TextField} from "react-admin";
import {ListGuesser} from "@api-platform/admin";
import {demandeStatuts} from "./status";

const DemandeListe = (props) => (
    <ListGuesser {...props}>
        <TextField source="titre" label="Titre"/>
        <TextField source="createur.nom" label="Nom de l'auteur"/>
        <TextField source="createur.prenom" label="Prénom de l'auteur"/>
        <DateField source="messages.0.datetime" showTime={true} label="Date de réception du message"/>
        <SelectField source="statut" choices={demandeStatuts} optionText="statutTexte" optionValue="statutCode"/>
    </ListGuesser>
)

export default DemandeListe;