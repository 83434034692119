import React, {useState} from "react";
import {Edit, SelectInput, SimpleForm, TextField, TextInput, Toolbar} from "react-admin";
import {demandeStatuts} from "./status";
import {Button, InputLabel, Link} from "@material-ui/core";
import AddNewButton from "../common/addNewButton";

const ConversationField = ({record}) => {

    const [refresh, setRefresh] = useState(false)
    const IdCreateurDemande = record['createur']['@id'];

    return (
        <div>
            <InputLabel shrink={true}>Conversation</InputLabel>
            {record['messages'].map((msg) => {
                const isCreateurDemande = msg['auteur'] ? IdCreateurDemande === msg['auteur']['@id'] : false;
                console.log(msg['fichier']);
                return (
                    <div style={{
                        backgroundColor: isCreateurDemande ? 'var(--primary)' : 'var(--secondary)',
                        marginTop: ".5em",
                        marginLeft: isCreateurDemande ? '0' : 'auto',
                        marginRight: isCreateurDemande ? 'auto' : '0',
                        color: 'white',
                        padding: ' .8em',
                        borderRadius: isCreateurDemande ? '0em .8em .8em .8em' : '.8em 0em .8em .8em',
                        width: '50%',
                        whiteSpace: 'pre-line'
                    }}>
                        <p style={{
                            marginTop: '0',
                            fontWeight: 'bold'
                        }}>{msg['auteur'] ? msg['auteur']['nomPrenom'] : 'BeBroker'}</p>
                        <p style={{marginTop: '0'}}>{msg['text']}</p>
                        {
                            msg['fichier'].map((f) => {
                                return (
                                    <div><Link href={process.env.REACT_APP_UPLOADS_PATH + f['chemin']} target="_blank" refedownload rel="noopener" style={{color: isCreateurDemande ? "" : "var(--primary)"}}>{f['titre']}</Link></div>
                                )
                            })
                        }
                        <div><AddNewButton createPath="/fichiers/create" property="message" relId={msg['@id']} label="Ajouter un fichier à ce message" style={{color: isCreateurDemande ? "" : "var(--primary)"}}/></div>
                        <small>{msg['datetime']}</small>
                    </div>
                )
            })}
            <textarea rows="8" id="newMessageTextarea" style={{width: '100%', marginTop: '1em'}}/>
            <Button style={{margin: '1em 0'}} variant="contained" color="primary" onClick={(e => {
                const input = document.getElementById('newMessageTextarea');
                const text = input.value;

                if (text.length > 0) {
                    const newMessage = {
                        text: text,
                        statut: 100,
                        datetime: (new Date()).toISOString(),
                        fichier: []
                    };

                    record['messages'].push(newMessage);
                    input.value = "";
                    setRefresh(!refresh);
                }
            })}>Envoyer le message</Button>
        </div>
    );
}

const DemandeEdit = (props) => {
    return (<Edit {...props}>
            <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton/>} redirect={false}>
                <TextInput source="titre" label="Titre de la demande"/>
                <TextField source="createur.nomPrenom" label="Demande faite par"/>
                <SelectInput source="statut" choices={demandeStatuts} optionText="statutTexte" optionValue="statutCode"/>
                <ConversationField props={props}/>
            </SimpleForm>
        </Edit>
    );
}


export default DemandeEdit;