import React, {useState} from "react";
import {
    ArrayInput,
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    Button,
    DateInput,
    Edit,
    fetchEnd,
    fetchStart,
    FormDataConsumer,
    FormTab,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    useNotify,
    NumberInput
} from "react-admin";
import {CustomAddButton, CustomRemoveButton} from "../common/customButtons";
import AddNewButton from "../common/addNewButton";
import {useDispatch} from 'react-redux';
import {roles} from "./RolesField";


const TestField = (props) => {

    console.log(props);

    return (
        <div>blabla</div>
    )
};

const MandatButton = ({record}) => {
    const dispatch = useDispatch();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        setLoading(true);
        dispatch(fetchStart());
        fetch(process.env.REACT_APP_API_BASEPATH + `${record.id}/mandat`, {
            method: 'GET',
            headers: {Authorization: `Bearer ${localStorage.getItem("token")}`}
        })
            .then(() => {
                notify('Mandat émis');
            })
            .catch((e) => {
                notify('Erreur lors de l\'émission du mandat', 'error')
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd());
            });
    };
    return <Button label="Émettre un mandat" onClick={handleClick} disabled={loading}/>;
};

const ProfileEdit = (props) => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="Profil">
                <TextInput source="nom" label="Nom" validate={required()}/>
                <TextInput source="prenom" label="Prénom"/>
                <DateInput source="dob" label="Date de naissance"/>
                <TextInput source="adresse" label="Adresse"/>
                <TextInput source="npa" label="NPA"/>
                <TextInput source="localite" label="Localité"/>
                <SelectInput source="permissejour" label="Permis de séjour" choices={[
                    {id: 0, name: 'Aucun'},
                    {id: 'B', name: 'Permis B'},
                    {id: 'C', name: 'Permis C'},
                    {id: 'F', name: 'Permis F'},
                    {id: 'G', name: 'Permis G'},
                    {id: 'L', name: 'Permis L'},
                    {id: null, name: 'Autres'},
                ]}/>
                <TextInput source="telephone1" label="Téléphone"/>
                <TextInput source="telephone2" label="Téléphone supplémentaire"/>
                <ReferenceArrayInput reference="profiles" source="profiles" label="Responsables"
                                     filterToQuery={searchText => ({simplesearch: searchText})}>
                    <AutocompleteArrayInput optionText="nomPrenom"/>
                </ReferenceArrayInput>
                <TextInput multiline source="remarques" label="Remarques" fullWidth={true}/>
                <MandatButton/>
            </FormTab>

            <FormTab label="Objets assurés">
                <ArrayInput source="objetsAssures" className="collectionTitle" label="Objets assurés">
                    <SimpleFormIterator addButton={<CustomAddButton label="Ajouter un nouvel objet assuré"/>}
                                        removeButton={<CustomRemoveButton label="Supprimer l'objet assuré"/>}>
                        <h2>Objet assuré</h2>
                        <TextInput source="nom" label="Nom" validate={required()}/>
                        <ReferenceInput reference="objet_assure_types" source="type" label="Type" validate={required()}>
                            <SelectInput optionText="name"/>
                        </ReferenceInput>
                        <ArrayInput source="polices" label="Polices" className="collectionTitle">
                            <SimpleFormIterator addButton={<CustomAddButton label="Ajouter une nouvelle police"/>}
                                                removeButton={<CustomRemoveButton label="Supprimer la police"/>}>
                                <h3>Police</h3>
                                <TextInput source="numero" label="Numéro" validate={required()}/>
                                <DateInput source="echeance" label="Date d'échéance" validate={required()}/>
                                <NumberInput source="resiliation" label="Délai de résiliation (mois)" validate={required()}/>
                                <ReferenceInput source="type" reference="police_types" label="Type"
                                                validate={required()}
                                                filterToQuery={searchText => ({"name": searchText})}>
                                    <AutocompleteInput optionText="fullName"/>
                                </ReferenceInput>
                                <ArrayInput source="infos" label="Informations sur la police">
                                    <SimpleFormIterator
                                        addButton={<CustomAddButton label="Ajouter une information sur la police"/>}
                                        removeButton={<CustomRemoveButton
                                            label="Supprimer l'information sur la police"/>}>
                                        <TextInput source="value"/>
                                    </SimpleFormIterator>
                                </ArrayInput>
                                <ArrayInput source="fichier" label="Documents de la police">
                                    <SimpleFormIterator disableAdd>
                                        <FormDataConsumer>
                                            {({getSource, scopedFormData}) => {
                                                getSource("titre");
                                                if (scopedFormData) {
                                                    return (
                                                        <div style={{paddingTop: ".7em"}}>
                                                            <a href={process.env.REACT_APP_UPLOADS_PATH + scopedFormData['chemin']}
                                                               target="_blank">{scopedFormData['titre']}</a>
                                                        </div>

                                                    );
                                                }
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                                <FormDataConsumer>
                                    {({getSource, scopedFormData}) => {
                                        if (scopedFormData) {
                                            return (
                                                <AddNewButton createPath="/fichiers/create"
                                                              label="Ajouter un document à la police"
                                                              relId={scopedFormData['@id']} property="police"/>
                                            );
                                        } else {
                                            return <p>Veuillez d'abord sauvegarder avant d'ajouter des documents</p>
                                        }
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <ArrayInput source="infos" className="collectionTitle" label="Informations sur l'objet assuré">
                            <SimpleFormIterator
                                addButton={<CustomAddButton label="Ajouter une information à l'objet assuré"/>}
                                removeButton={<CustomRemoveButton label="Supprimer l'information de l'objet assuré"/>}>
                                <ReferenceInput reference="objet_assure_info_types" source="infotype"
                                                label="Type d'information">
                                    <SelectInput optionText="name"/>
                                </ReferenceInput>
                                <TextInput source="value" label="Valeur"/>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <ArrayInput source="fichier" label="Documents de l'objet assuré">
                            <SimpleFormIterator disableAdd>
                                <FormDataConsumer>
                                    {({getSource, scopedFormData}) => {
                                        getSource("titre");
                                        if (scopedFormData) {
                                            return (
                                                <div style={{paddingTop: ".7em"}}>
                                                    <a href={process.env.REACT_APP_UPLOADS_PATH + scopedFormData['chemin']}
                                                       target="_blank">{scopedFormData['titre']}</a>
                                                </div>

                                            );
                                        }
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <FormDataConsumer>
                            {({getSource, scopedFormData}) => {
                                if (scopedFormData) {
                                    return (
                                        <AddNewButton createPath="/fichiers/create"
                                                      label="Ajouter un document à l'objet assuré"
                                                      relId={scopedFormData['@id']} property="objetassure"/>
                                    );
                                } else {
                                    return <p>Veuillez d'abord sauvegarder avant d'ajouter des documents</p>
                                }
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>

            <FormTab label="Accès">
                <TextInput source="user.username" label="Nom d'utilisateur" />
                    {localStorage.getItem("roles") && localStorage.getItem("roles").includes('ROLE_ADMIN') ?
                        [
                            <BooleanInput source="user.enabled" label="Utilisateur actif ?"/>,
                            <SelectArrayInput source="user.roles" label="Type" choices={roles} optionValue='value'
                                              optionText='role'/>
                        ] : null }
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default ProfileEdit;
